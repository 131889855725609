import React from 'react';
import { AppProvider } from './context/AppContext';
import ErrorWrapper from './components/ErrorWrapper';
import { RecentList } from './components/RecentList';
import { RecentPage } from './components/RecentPage';

function App({ type }) {
  return (
    <>
      <AppProvider>
        <ErrorWrapper>
          {type === 'page' ? <RecentPage /> : <RecentList />}
        </ErrorWrapper>
      </AppProvider>
    </>
  );
}

export default App;
