import React, { useState } from 'react';

export const SongLink = ({ cover, artist, title, album, startTime }) => {
  const [isImageBroken, setIsImageBroken] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const formatTime = time => {
    const dateTime = new Date(time);
    // const dateTime = new Date(time + " GMT");
    let h = dateTime.getHours();
    let a = " am";
    if(h === 0) {
      h = 12;
    } else if(h > 12) {
      h = h-12;
      a = " pm";
    }
    let m = dateTime.getMinutes();
    if(m < 10) {
      m = "0" + m;
    }
    let s = dateTime.getSeconds();
    if(s < 10) {
      s = "0" + s;
    }
    return h + ":" + m + ":" + s + a;
  }

  return (
    <li className='recent-song'>
      <a
        className='recent-song-link player-link'
        href={window.ASC_PLAYER_URL || '/listen'}
      >
        <img
          className={`cover ${isImageBroken ? 'hide' : ''} ${
            isImageLoading ? 'loading' : ''
          }`}
          onError={() => setIsImageBroken(true)}
          onLoad={() => setIsImageLoading(false)}
          alt={album}
          src={cover}
        />
        <span className='now-playing'>Now Playing</span>
        <div className={`details ${isImageBroken ? 'no-image' : ''}`}>
          <span className='title'>{title}</span>
          <span className='artist'>{artist}</span>
          {startTime && <span className='start-time'>{formatTime(startTime)}</span>}
          <span className='album hide'>{album}</span>
        </div>
      </a>
    </li>
  );
};
