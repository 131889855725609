import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const widget = document.getElementById('asc-recently-played-widget');
const page = document.getElementById('asc-recently-played-page');
if (widget) {
  ReactDOM.render(<App type='widget' />, widget);
}
if (page) {
  ReactDOM.render(<App type='page' />, page);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
