import React, { createContext, useReducer } from 'react';

const initialState = {
  recentList: [
    {
      songId: '',
      songTitle: '',
      albumCover: '',
      artist: '',
      albumTitle: ''
    }
  ],
  currentSong: {
    songId: '',
    songTitle: '',
    albumCover: '',
    artist: '',
    albumTitle: ''
  },
  apiError: false
};

const AppContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_LIST':
      if (
        action.recentList.length > 0 &&
        action.recentList[0].songId !== state.recentList[0].songId
      ) {
        const replaceCount = state.recentList.length;
        state.recentList.splice(0, replaceCount, ...action.recentList);

        return {
          ...state,
          currentSong: action.recentList[0],
          apiError: false
        };
      } else {
        return {
          ...state,
          apiError: false
        };
      }
    case 'ADD_TO_LIST':
      const newLatest = action.recentList.length > 0 ? action.recentList[0] : undefined;
      if(state.recentList.length > 0 && newLatest &&
          state.recentList[0].songId !== newLatest.songId) {
        state.recentList.unshift(newLatest);
      }
      return {
        ...state,
        currentSong: newLatest,
        apiError: false
      };
    case 'HIDE_LIST':
      return {
        ...state,
        apiError: true
      };
    default:
      return state;
  }
};

const AppProvider = ({ children }) => {
  const [appState, appDispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ appState, appDispatch }}>
      {children}
    </AppContext.Provider>
  );
};

const AppConsumer = AppContext.Consumer;

export { AppContext, AppProvider, AppConsumer };
