import React, { useContext, useEffect, useState } from 'react';
import { Song } from './Song';
import { SongLink } from './SongLink';
import { AppContext } from '../context/AppContext';
import Axios from 'axios';
import { useInterval } from '../hooks/Interval';
import { useTransition, animated } from 'react-spring';

export const RecentPage = () => {
  const { appState, appDispatch } = useContext(AppContext);
  const [nextCheck, setNextCheck] = useState(new Date());
  const refreshInterval = window.ASC_REFRESH_RATE || 30;

  const getInitialLoad = () => {
      const ts = new Date().getTime() / 1000;
      var newTs = Math.round(ts / 10);
      const url = window.ASC_HISTORY_API_URL.indexOf('?') > 0 ? window.ASC_HISTORY_API_URL + "&ts=" + newTs : window.ASC_HISTORY_API_URL + "?ts=" + newTs;
      Axios.get(url).then(({ data }) => {
        let songList = [];
        if(data.songs && data.songs.length > 0){
          if(data.songs[0].endTime) {
            setNextCheck(new Date(data.songs[0].endTime));
          }
          data.songs.forEach((song) => {
            songList.push(formatSong(song));
          });
          appDispatch({
            type: 'UPDATE_LIST',
            recentList: songList,
          });
        } else {
          appDispatch({type: 'HIDE_LIST'});
        }
      });
  }

  const getRecents = () => {
    var now = new Date();
    if(now.getTime() > nextCheck.getTime()) {
      const ts = new Date().getTime() / 1000;
      var newTs = Math.round(ts / 10);
      const url = window.ASC_RECENT_API_URL.indexOf('?') > 0 ? window.ASC_RECENT_API_URL + "&ts=" + newTs : window.ASC_RECENT_API_URL + "?ts=" + newTs;
      Axios.get(url).then(({ data }) => {
        let songList = [];
        if(data.songs && data.songs.length > 0){
          if(data.songs[0].endTime) {
            setNextCheck(new Date(data.songs[0].endTime));
          }
          data.songs.forEach((song) => {
            songList.push(formatSong(song));
          });
          appDispatch({
            type: 'ADD_TO_LIST',
            recentList: songList,
          });
        } else {
          appDispatch({type: 'HIDE_LIST'});
        }
      });
    }
  };

  useEffect(() => {
    getInitialLoad();
  }, []);

  useInterval(getRecents, refreshInterval * 1000);

  // const transitions = useTransition(appState.recentList, song => song.songId, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { transform: 'translateX(-100%)', opacity: 0, position: 'absolute' },
  //   trail: 25
  // });

  const infoTransition = useTransition(
    appState.currentSong,
    (song) => song.songTitle,
    {
      from: { opacity: 0, position: 'absolute' },
      enter: { opacity: 1, position: 'relative' },
      leave: { opacity: 0, position: 'absolute' },
    }
  );

  return (
    <div className='recently-played-page'>
      {infoTransition.map(({ item: song, props, key }) => (
        <animated.div className='song-info' key={key} style={props}>
          <span className='now-playing-label'>Now Playing:</span>
          <span className='title'>{song.songTitle}</span>
          <span className='artist'>{song.artist}</span>
          <a
            className='recents-link-now-playing'
            href={window.ASC_RECENTS_URL || '/recently-played'}
          >
            <i className='fa fa-play'></i>
            <span> See Recently Played</span>
          </a>
        </animated.div>
      ))}
      <a className='player-link' href={window.ASC_PLAYER_URL || '/listen'}>
        <i className='fa fa-play'></i>
        <span> Listen Now</span>
      </a>
      {appState.apiError ? (
        <div className="broken-api-page-wrapper">
          <h3 className="error">Unable to get recently played songs</h3>
          <h4 className="sub">Please check back later</h4>
        </div>
      ) : (
        <ul className='recent-list'>
          {appState.recentList.map((song, index) =>
            index === 0 ? (
              <SongLink
                key={song.songId + song.startTime}
                cover={song.albumCover}
                artist={song.artist}
                title={song.songTitle}
                album={song.albumTitle}
                startTime={song.startTime}
              />
            ) : (
              <Song
                key={song.songId + song.startTime}
                cover={song.albumCover}
                artist={song.artist}
                title={song.songTitle}
                album={song.albumTitle}
                startTime={song.startTime}
              />
            )
          )}
        </ul>
      )}
    </div>
  );
};

const formatSong = (song) => {
  return {
    songId: song.id.toString(),
    artist: song.artist,
    songTitle: song.title,
    albumTitle: song.album || '',
    albumCover: song.artwork ? song.artwork : undefined,
    startTime: song.startTime,
    endTime: song.endTime,
  };
};
